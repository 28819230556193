<template>
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6046_260829"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="17"
            height="16"
        >
            <rect x="0.8125" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6046_260829)">
            <path
                d="M3.9236 14C3.6236 14 3.36342 13.8898 3.14305 13.6695C2.92268 13.4491 2.8125 13.1889 2.8125 12.8889V3.1111C2.8125 2.8111 2.92268 2.55092 3.14305 2.33055C3.36342 2.11018 3.6236 2 3.9236 2H7.14585C7.44584 2 7.70602 2.11018 7.9264 2.33055C8.14677 2.55092 8.25695 2.8111 8.25695 3.1111V12.8889C8.25695 13.1889 8.14677 13.4491 7.9264 13.6695C7.70602 13.8898 7.44584 14 7.14585 14H3.9236ZM10.4792 14C10.1792 14 9.91898 13.8898 9.6986 13.6695C9.47823 13.4491 9.36805 13.1889 9.36805 12.8889V3.1111C9.36805 2.8111 9.47823 2.55092 9.6986 2.33055C9.91898 2.11018 10.1792 2 10.4792 2H13.7014C14.0014 2 14.2616 2.11018 14.482 2.33055C14.7023 2.55092 14.8125 2.8111 14.8125 3.1111V12.8889C14.8125 13.1889 14.7023 13.4491 14.482 13.6695C14.2616 13.8898 14.0014 14 13.7014 14H10.4792ZM13.7014 3.1111H10.4792V12.8889H13.7014V3.1111Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>
