<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import {
    VBtn,
    VDialog,
    VTextField,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
} from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import { useField } from 'vee-validate';
import { string } from 'zod';

const isShowingDialog = defineModel<boolean>({ default: false });

const compProps = withDefaults(
    defineProps<{
        title?: string;
        text?: string;
        destructive?: boolean;
        confirmRequired?: boolean;
        confirmButtonText?: string;
        confirmButtonDisabled?: boolean;
        cancelButtonText?: string;
        width?: string | number;
    }>(),
    {
        title: undefined,
        text: undefined,
        confirmRequired: false,
        confirmButtonText: undefined,
        cancelButtonText: undefined,
        width: 360,
    }
);

const { t } = useI18n({
    messages: {
        nl: {
            confirm: 'Bevestig',
            delete: 'VERWIJDER',
            delete_label: 'Type "VERWIJDER" om te bevestigen',
            error: {
                no_match: 'Type "VERWIJDER"',
            },
        },
        en: {
            confirm: 'Confirm',
            delete: 'DELETE',
            delete_label: 'Type "DELETE" to confirm',
            error: {
                no_match: 'Type "DELETE"',
            },
        },
    },
});

const emit = defineEmits<{ (e: 'cancel'): void; (e: 'confirm'): void }>();

const hasConfirmationField = ref(compProps.confirmRequired);
const confirmationWord = useField(
    'confirmation',
    toTypedSchema(
        string()
            .min(1, t('error.required_field'))
            .regex(/VERWIJDER|DELETE/, t('error.no_match'))
    )
);
const hasInvalidConfirmation = computed(() => {
    if (compProps.destructive && compProps.confirmRequired) {
        return !confirmationWord.meta.valid;
    }
    return false;
});

function handleCancel() {
    isShowingDialog.value = false;
    emit('cancel');
}

function handleConfirm() {
    isShowingDialog.value = false;
    emit('confirm');
}
</script>

<template>
    <v-dialog v-model="isShowingDialog" v-bind="$attrs" :width>
        <template #activator="{ props }">
            <slot name="activator" :props />
        </template>
        <v-card>
            <v-card-title class="pt-6 pb-0 px-6">
                <h2 v-if="title" class="text-title-large">{{ title }}</h2>
            </v-card-title>
            <v-card-text class="pt-0 pb-4">
                <p v-if="text" class="text-body-medium">{{ text }}</p>

                <slot />

                <v-text-field
                    v-if="destructive && hasConfirmationField"
                    v-model="confirmationWord.value.value"
                    class="mt-8 mb-0"
                    :clearable="false"
                    :label="t('delete_label')"
                    :error-messages="confirmationWord.errorMessage.value"
                ></v-text-field>
            </v-card-text>
            <v-card-actions class="pa-6 justify-end">
                <v-btn-text @click="handleCancel">{{
                    cancelButtonText || t('cancel')
                }}</v-btn-text>
                <v-btn
                    :disabled="confirmButtonDisabled || hasInvalidConfirmation"
                    :color="destructive ? 'error' : 'primary'"
                    @click="handleConfirm"
                >
                    {{ confirmButtonText || t('confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
