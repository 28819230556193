import type { IconOptions } from 'vuetify';
import { h } from 'vue';
import { VLigatureIcon } from 'vuetify/components';
import custom from '@/assets/icons';

export default {
    defaultSet: 'mso',
    aliases: {
        column_left: 'custom:column_left',
        column_right: 'custom:column_right',
        row_above: 'custom:row_above',
        row_below: 'custom:row_below',
        date: 'event',
        dashboard: 'dashboard',
        portfolio: 'inventory_2',
        program: 'folder',
        project: 'article',
        idea: 'emoji_objects',
        theme: 'work_outline',
        department: 'workspaces',
        tag: 'label',
        file: 'custom:file',
        settings: 'settings',
        support: 'info',
        news: 'new_releases',
        logout: 'logout',
        user: 'supervisor_account',
        framework: 'grid_on',
        metroview: 'directions_subway',
        back: 'arrow_back',
        full_screen: 'aspect_ratio',
        zoom_in: 'zoom_in',
        zoom_out: 'zoom_out',
        edit: 'edit',
        view: 'visibility',
        hide: 'visibility_off',
        delete: 'delete',
        goal: 'flag',
        stakeholder: 'contacts',
        on_hold: 'event_busy',
        in_progress: 'event_upcoming',
        done: 'event_available',
        subproject: 'call_split',
        subdirectory: 'mso:subdirectory_arrow_right',
        statistics: 'insert_chart',
        pie_chart: 'pie_chart',
        dashboard_customize: 'dashboard_customize',
        notification: 'notifications_active',
        bottleneck: 'report',
        awaiting: 'hourglass_empty',
        link: 'link',
        unlink: 'link_off',
        cell_location: 'location_on',
        add: 'add_circle_outline',
        download: 'download',
        upload: 'upload_file',
        palette: 'palette',
        status: 'flowsheet',
        more: 'mdo:more_vert',
        sidebar_in: 'custom:sidebar_in',
        sidebar_out: 'custom:sidebar_out',
        reorder: 'reorder',
        close: 'close',
        error: 'error_outline',
        fold: 'custom:fold',
        unfold: 'custom:unfold',
        pan_zoom: 'pan_zoom',
        transition: 'route',
        customer_needs: 'account_box',
        foundation: 'foundation',
        strategic_role: 'mso:psychology',
        developments: 'auto_graph',
        drivers: 'explore',
        unfold_more: 'unfold_more',
        unfold_less: 'unfold_less',
        undo: 'undo',
        sort: 'arrow_drop_down',
        reverse_sort: 'arrow_drop_up',
        program_manager: 'person',
        project_manager: 'contact_page',
        team: 'groups',
        description: 'text_snippet',
        escalation: 'campaign',
        highlight: 'star',
        preview: 'visibility',
        favorite: 'star',
        favorite_filled: 'custom:star_filled',
        expand: 'expand_more',
        collapse: 'expand_less',
        reset: 'restart_alt',
        key: 'vpn_key',
        warning: 'warning',
        filters: 'tune',
        attach: 'attach_file',
        application: 'mdo:terminal',
        personal_programs: 'mso:folder_supervised',
        personal_projects: 'custom:personal_projects',
        lock: 'mdo:lock',
        finished_projects: 'list_alt_check',
        save: 'save',
    },
    sets: {
        mdo: {
            component: (props: any) =>
                h(VLigatureIcon, {
                    ...props,
                    class: 'material-icons-outlined',
                }),
        },
        mso: {
            component: (props: any) =>
                h(VLigatureIcon, {
                    ...props,
                    class: 'material-symbols-outlined',
                }),
        },
        custom,
    },
} as IconOptions;
