import { h } from 'vue';

import { type IconSet, type IconProps } from 'vuetify';
import SidebarIn from './SidebarIn.vue';
import SidebarOut from './SidebarOut.vue';
import IconFold from './IconFold.vue';
import IconUnfold from './IconUnfold.vue';
import IconFile from './IconFile.vue';
import IconStarFilled from './IconStarFilled.vue';
import IconColumnLeft from './IconColumnLeft.vue';
import IconColumnRight from './IconColumnRight.vue';
import IconRowAbove from './IconRowAbove.vue';
import IconRowBelow from './IconRowBelow.vue';
import PersonalProjects from './PersonalProjects.vue';

const customSvgNameToComponent: any = {
    fold: IconFold,
    unfold: IconUnfold,
    sidebar_in: SidebarIn,
    sidebar_out: SidebarOut,
    file: IconFile,
    star_filled: IconStarFilled,
    personal_projects: PersonalProjects,
    column_left: IconColumnLeft,
    column_right: IconColumnRight,
    row_above: IconRowAbove,
    row_below: IconRowBelow,
};

const custom: IconSet = {
    component: (props: IconProps) =>
        h(props.tag, [
            h(customSvgNameToComponent[props.icon as string], {
                class: 'v-icon__svg',
            }),
        ]),
};

export default custom;
