<template>
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_6046_285346"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="17"
            height="16"
        >
            <rect x="0.8125" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6046_285346)">
            <path
                d="M3.9236 7.44445C3.6236 7.44445 3.36342 7.33427 3.14305 7.1139C2.92268 6.89352 2.8125 6.63334 2.8125 6.33335V3.1111C2.8125 2.8111 2.92268 2.55092 3.14305 2.33055C3.36342 2.11018 3.6236 2 3.9236 2H13.7014C14.0014 2 14.2616 2.11018 14.482 2.33055C14.7023 2.55092 14.8125 2.8111 14.8125 3.1111V6.33335C14.8125 6.63334 14.7023 6.89352 14.482 7.1139C14.2616 7.33427 14.0014 7.44445 13.7014 7.44445H3.9236ZM3.9236 14C3.6236 14 3.36342 13.8898 3.14305 13.6695C2.92268 13.4491 2.8125 13.1889 2.8125 12.8889V9.66665C2.8125 9.36666 2.92268 9.10648 3.14305 8.8861C3.36342 8.66573 3.6236 8.55555 3.9236 8.55555H13.7014C14.0014 8.55555 14.2616 8.66573 14.482 8.8861C14.7023 9.10648 14.8125 9.36666 14.8125 9.66665V12.8889C14.8125 13.1889 14.7023 13.4491 14.482 13.6695C14.2616 13.8898 14.0014 14 13.7014 14H3.9236ZM3.9236 12.8889H13.7014V9.66665H3.9236V12.8889Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>
